





















import { Component, Vue } from 'vue-property-decorator';
import StateToggle from '@/components/base/StateToggle.vue';
import ControlSwitch from './ControlSwitch/ControlSwitch.vue';
import ControlTabLampOverwrite from './ControlTabLampOverwrite/ControlTabLampOverwrite.vue';
import ControlTabCircuitOverwrite from './ControlTabCircuitOverwrite/ControlTabCircuitOverwrite.vue';
import ControlTabGroupOverwrite from './ControlTabGroupOverwrite/ControlTabGroupOverwrite.vue';
import { namespace } from 'vuex-class';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const admin = namespace('admin');

@Component({
  name: 'ControlTab',
  components: {
    StateToggle,
    ControlSwitch,
    ControlTabLampOverwrite,
    ControlTabCircuitOverwrite,
    ControlTabGroupOverwrite
  }
})
/**
 * @vuese
 * @group Control Card Group
 * Control Card - Control Tab content
 */
export default class ControlTab extends Vue {
  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get permissionDevicesWrite() {
    return this.GetPermissions.find((item) => item.type === 'devices')?.write;
  }

  get permissionCircuitsWrite() {
    return this.GetPermissions.find((item) => item.type === 'circuits')?.write;
  }

  get permissionGroupsWrite() {
    return this.GetPermissions.find((item) => item.type === 'groups')?.write;
  }
}
