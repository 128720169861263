<template functional>
  <div
    class="c-control-switch">
    <v-switch
      class="c-control-switch__toggle"
      @change="listeners.change"
      color="success"
      inset
      ></v-switch>
    <span v-if="props.label" class="c-control-switch__label">{{ props.label }}</span>
  </div>
</template>

<script>
import Vue from 'vue';

/**
 * @vuese
 * @group Control Card Group
 * Control tab switch
 */
export default Vue.extend({
  name: 'ControlSwitch',
  functional: true,
  props: {
    label: {
      type: String
    }
  }
});
</script>

<style scoped lang="scss">
.c-control-switch {
  padding: 10px;
  background-color: #f2f2f2;;
  height: 35px;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__toggle {
    flex-basis: 10%;
  }
  &__label {
    margin: 0.3rem;
    font-size: 1.1rem;
    flex-basis: 80%
  }
}</style>
