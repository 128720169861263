





























































import {
  Component, Vue, Prop, PropSync, Ref, Watch
} from 'vue-property-decorator';
import { Option, isOptionsArr } from '@/components/base/BaseSelect.vue';
import RoundDutySlider from './RoundDutySlider/RoundDutySlider.vue';
import DateTimePicker from './DateTimePicker/DateTimePicker.vue';

@Component({
  components: {
    DateTimePicker,
    RoundDutySlider,
  },
})
/**
 * @group Control Card Group
 * A card for displaing/setting duty of a selected item (device/circuit/group)
 */
export default class OverwriteCard extends Vue {
  // used in parent component
  @Ref() loaderRef!: any

  // disable user inputs
  @Prop(Boolean) readonly disabled!: boolean;

  // available select options (devices/circuits/groups)
  @Prop({ type: Array, validator: isOptionsArr }) readonly selectOptions!: Array<Option>

  // placeholder text for Select item
  @Prop({ type: String, required: true }) readonly selectPlaceholder!: string

  // synced duty value
  @PropSync('duty', { type: Number }) syncedDuty!: number;

  // synced endDate - ISO string
  @PropSync('endDate', { type: String }) syncedEndDate!: string;

  // synced id of the selected item
  @PropSync('selectedItemID', { type: Number }) syncedSelectedItemId!: number | null

  // synced id of the selected item
  @Prop({ type: Boolean, required: false }) lampIsOnline!: boolean | null

  // synced id of the selected item
  @Prop({ validator: (prop) => typeof prop === 'boolean' || prop === null, required: true }) isDefaultScenario!: boolean | null

  showDatetimePicker = true;

  // disable overwrite actions when disabled explicitly by parent component
  // or if the user hasn't selected overwrite's end date
  // or if the date syncedEndDate is the past date
  get actionDisabled() {
    return this.disabled || !this.syncedEndDate || new Date(this.syncedEndDate) < new Date() || this.lampIsOnline === false;
  }

  get actionAutomaticControlDisabled() {
    return this.disabled || this.isDefaultScenario !== false || !this.syncedSelectedItemId || this.lampIsOnline === false;
  }

  @Watch('$i18n.locale')
  on$i18nLocaleChange() {
    this.forceRerender();
  }

  forceRerender() {
    this.showDatetimePicker = false;
    this.$nextTick().then(() => {
      this.showDatetimePicker = true;
    });
  }
}
