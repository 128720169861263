
import Vue from 'vue';
import RoundSlider from 'vue-round-slider';

/**
 * @group Control Card Group
 * Rounded slider element for setting/displaying duty value
 */
export default Vue.extend({
  name: 'RoundDutySlider',
  functional: true,
  props: {
    // disable user input
    disabled: {
      default: true,
      type: Boolean
    },
    // duty value
    value: {
      type: Number
    }
  },
  render(createElement, { props, listeners }) {
    return createElement(RoundSlider, {
      props: {
        disabled: props.disabled,
        value: props.value,
        startAngle: '-60',
        radius: '50',
        width: '10',
        pathColor: '#fff',
        rangeColor: '#ffc200',
        endAngle: '+300',
        update({ value }) {
          const emitChange = listeners.input as (payload: Number) => void;
          emitChange(value);
        }
      },
    });
  }
});
