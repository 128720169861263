
import Vue from 'vue';
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';

/**
 * @group Control Card Group
 * Control Card - Control Tab
 */
export default Vue.extend({
  name: 'DateTimePicker',
  functional: true,
  props: {
    // disable user input
    disabled: {
      type: Boolean,
      default: false
    },
    // selected date - ISO string
    value: {
      type: String,
    },
    locale: {
      type: String,
    }
  },
  render(createElement, { props, slots, listeners }) {
    Settings.defaultLocale = props.locale;
    const dateTimePicker = createElement(Datetime, {
      props: {
        hideBackdrop: true,
        type: 'datetime',
        minDatetime: (new Date()).toISOString(),
        inputClass: 'c-date-time-picker__input',
        value: props.value,
      },
      attrs: {
        disabled: props.disabled,
      },
      on: listeners
    });
    const dateTimePickerLabel = createElement('small', {
      class: 'c-date-time-picker__label',
    }, slots().default); // slot for the label
    return createElement('div', {
      class: 'c-date-time-picker',
    }, [dateTimePickerLabel, dateTimePicker]);
  }
});
